.dialogItemLabel{
    min-width: 150px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: larger;
    font-weight: 500;
}

.dialogSection{
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

.endpointNestedDiv{
    margin: 20px;
}

.ai-dialogSection{
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

.ai-dialogItemLabel{
    min-width: 150px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: larger;
    font-weight: 500;
    width: 230px;
    margin-right: 30px;
}

.primaryHeader{
    display: flex;
    /* justify-content: center; */
    border: 2px solid #c9c7c7;
    /* font-size: x-large; */
    padding: 8px;
    margin: 10px;
    border-radius: 20px;
    background-color: #f8f8f8;
    /* font-weight: 900; */

}


.fileUploadText{
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: left;
    height: 20px;
}
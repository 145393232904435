
.error-container {
    display: flex;
    height: calc(100vh - 100px);
    padding: 150px;
    color: black;
 
  }

  .errorTextContainer{
    width: 60%;
    height: 100%;
    display: flex;
   flex-direction: column;
   flex-direction: column;
   justify-content: center;
   margin-left :5% ;
  }
  .errorImageContainer{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .error-container h1 {
    font-size: 36px;
    margin-bottom: 0.5rem;
  }
  
  .error-container p {
    font-size: 30px;
    
  }
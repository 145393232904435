.filedrag-drop-container {
    border: 1px dashed #aaa;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 45px;
    background: #fbffff;
    width: 100%;
    height: 100%; /* Set height to 48% of the viewport height */
    display: flex; /* Enable Flexbox */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  
  .filedrag-drop-container.dragging {
    border-color: #0A84A0;
  }
  .selectedImage{
    /* width: 434px;
    height: 390px;  */
    object-fit: contain;
  }

.react-pdf__Page__canvas {
    margin: 0 auto;
    /* width: 75% !important; */
    /* height: 100% !important; */
    border: 1px solid #d3cfcf;
}

.react-pdf__Page{
  background: transparent !important;
  margin: auto;
}


/* .react-pdf__Page{
  height: 400px;
    width: 400px;
} */

  @media(max-width: 1950px) {

    .filedrag-drop-container  {
      width: 100%;
      height: 100%;
    }
/* 
    .selectedImage{
      width: 434px;
      height: 500px; 
    }
    */
  }

  @media(max-width: 1550px) {

  

    
    /* .selectedImage{
      width: 434px;
      height: 390px;
    } */

   
  }


  @media(max-width: 1200px) {

    .filedrag-drop-container  {
      width:100%;
      height: 100%;
    }

    /* .selectedImage{
      width: 340px;
      height: 340px;
    } */

   
  }
  
  @media(max-width: 991px) {

    .filedrag-drop-container  {
      width: 100%;
      height: 100%;
    }

    /* .selectedImage{
      width: 358px;
      height: 358px;
    } */

  
   
  }

  @media(max-width: 479px) {
   
    .filedrag-drop-container  {
      width: 100%;
      height: 100%;
    }
/* 
    .selectedImage{
      width: 300px;
      height: 300px;
    } */

   

    
  
  }
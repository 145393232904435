@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


:root{
    /* ===== Colors ===== */
    --body-color: #E4E9F7;
    --sidebar-color: #FFF;
    --primary-color: #695CFE;
    --primary-color-light: #F6F5FF;
    --toggle-color: #DDD;
    --text-color: #707070;

    /* ====== Transition ====== */
    --tran-03: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.3s ease;
    --tran-05: all 0.3s ease;
}



::selection{
    background-color: var(--primary-color);
    color: #fff;
}

.sidebar.dark{
    --body-color: #18191a;
    --sidebar-color: #242526;
    --primary-color: #b2b4b6;
    --primary-color-light: #3a3b3c;
    --toggle-color: #fff;
    --text-color: #ccc;
}

/* ===== Sidebar ===== */
 .sidebar{
    /* position: fixed; */
    /* height: 80vh !important; */
    min-height: 30vh;
    overflow-y: auto;
    width: 320px;
    background: var(--sidebar-color);
    transition: var(--tran-05);
    z-index: 100;  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sidebar.close{
    width: 88px;
}

/* ===== Reusable code - Here ===== */
.sidebar li{
    height: 40px;
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding-left: 0;
    padding-right: 25px;
}

.sidebar header .image,
.sidebar .icon{
    min-width: 60px;
    border-radius: 6px;
}

.sidebar .icon{
    min-width: 60px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.sidebar .text,
.sidebar .icon{
    color: #fff;
    transition: var(--tran-03);
}

.sidebar .text {
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    opacity: 1;
    width: 235em;
    margin-left: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
.sidebar.close .text{
    opacity: 0;
}
/* =========================== */

.sidebar header{
    position: relative;
}

.sidebar header .image-text{
    display: flex;
    align-items: center;
}
.sidebar header .logo-text{
    display: flex;
    flex-direction: column;
}
header .image-text .name {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 600;
}

header .image-text .profession{
    font-size: 16px;
    margin-top: -2px;
    display: block;
}

.sidebar header .image{
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar header .image img{
    width: 40px;
    border-radius: 6px;
}

.sidebar header .toggle{
    position: absolute;
    top: 50%;
    right: -25px;
    transform: translateY(-50%) rotate(180deg);
    height: 25px;
    width: 25px;
    background-color: var(--primary-color);
    color: var(--sidebar-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    transition: var(--tran-05);
}

.sidebar .dark .sidebar header .toggle{
    color: var(--text-color);
}

.sidebar.close .toggle{
    transform: translateY(-50%) rotate(0deg);
}

/* .sidebar .menu{
    margin-top: 40px;
} */

.sidebar li.search-box{
    border-radius: 6px;
    background-color: var(--primary-color-light);
    cursor: pointer;
    transition: var(--tran-05);
}

.sidebar li.search-box input{
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--primary-color-light);
    color: var(--text-color);
    border-radius: 6px;
    font-size: 17px;
    font-weight: 500;
    transition: var(--tran-05);
}
.sidebar li a{
    list-style: none;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    width: 90%;
    cursor: pointer;
    border-radius: 6px;
    text-decoration: none;
    transition: var(--tran-03);
}

.liactive{
    background-color: var(--primary-color) !important;
    
}

.liactive .text{
    color: var(--sidebar-color);
}

.sidebar li a:hover{
    background-color: var(--primary-color);
}
.sidebar li a:hover .icon,
.sidebar li a:hover .text{
    color: var(--sidebar-color);
}
.sidebar .dark .sidebar li a:hover .icon,
.sidebar .dark .sidebar li a:hover .text{
    color: var(--text-color);
}

.sidebar .menu-bar{
    /* height: calc(100% - 65px); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    border-top: 1px solid;
}
.menu-bar::-webkit-scrollbar{
    display: none;
}
.sidebar .menu-bar .mode{
    border-radius: 6px;
    background-color: var(--primary-color-light);
    position: relative;
    transition: var(--tran-05);
}

.menu-bar .mode .sun-moon{
    height: 50px;
    width: 60px;
}

.mode .sun-moon i{
    position: absolute;
}
.mode .sun-moon i.sun{
    opacity: 0;
}
.sidebar .dark .mode .sun-moon i.sun{
    opacity: 1;
}
.sidebar .dark .mode .sun-moon i.moon{
    opacity: 0;
}

.menu-bar .bottom-content .toggle-switch{
    position: absolute;
    right: 0;
    height: 100%;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
}
.toggle-switch .switch{
    position: relative;
    height: 22px;
    width: 40px;
    border-radius: 25px;
    background-color: var(--toggle-color);
    transition: var(--tran-05);
}

.switch::before{
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    background-color: var(--sidebar-color);
    transition: var(--tran-04);
}

.sidebar .dark .switch::before{
    left: 20px;
}

.home{
    
    top: 0;
    top: 0;
    background-color:#f0f0f0;
    transition: var(--tran-05);
    width:calc(100vw - 320px)
}
.home .text{
    font-size: 30px;
    font-weight: 500;
    color: var(--text-color);
    padding: 12px 60px;
}

.sidebar.close ~ .home{
    left: 78px;
    height: 100vh;
    width: calc(100% - 78px);
}
.sidebar .dark .home .text{
    color: var(--text-color);
}

.cs-message-input__content-editor-wrapper{
    background-color: #FFF  !important;
}

.cs-message-input__content-editor {
    background-color: #FFF  !important;
}

.cs-chat-container .cs-message-input {

    margin-top: auto;
    padding: 0.3em 0 0.3em 0em;
    min-height: 4em;
    border: 1px solid #696565 !important;
    border-radius: 20px;
    color: rgba(0,0,0,.87);
    background-color: #fff !important;
}

.cs-message__content {
   background-color: gainsboro !important;
   
}

.cs-message--avatar-spacer{
    margin-left: 3.6em !important;
}

.cs-message--outgoing .cs-message__content {
    color: rgba(0,0,0,.87);
    background-color: gainsboro  !important;
    border-radius: 0.7em 0 0 0.7em;
}

.cs-button--send {
    color: #565859 !important;
    background-color: transparent  !important;
}

.cs-message-input__content-editor {
    box-sizing: border-box;
    flex-grow: 1;
    background-color: #c6e3fa;
    font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
    outline: 0;
    border-top: 0 none;
    border-right: 0 none;
    border-bottom: 0 none;
    border-left: 0 none;
    overflow: visible;
    color: rgba(0, 0, 0, .87);
    overflow-wrap: anywhere;
    word-break: break-word;
    font-weight: 500;
    font-size: medium;
}


.cs-message-input__content-editor::before {
   
    color:rgb(56 54 54 / 87%) !important;
    overflow-wrap: anywhere;
    word-break: break-word;
    font-weight: 700;
    font-size: 14px;
}

.parentDiv{
    display: flex;
    float: left;
    border-top: 1px solid gainsboro ;
    height:calc(100vh - 164px);
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(170, 170, 170); 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888787; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888787; 
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  .possibleQuestion{
    border: 1px solid #afabab;
    padding: 10px;
    background: gainsboro;
    font-size: 12px;
    margin: 10px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: 600;
    color: black;
  }

  .cs-avatar>img {
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    border-radius: unset !important;
}

.cs-message-input__tools {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin: auto;
}

.cs-message-list .cs-message.cs-message--outgoing, .cs-message-list .cs-message-group.cs-message-group--outgoing {
    margin-left: auto;
    margin-bottom: 2em !important;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure the loader is above other content */
  }


  .chat-overlay {
    position: fixed;
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    height: 100%;
    background-color: rgb(55 52 52 / 80%);

    z-index: 999;
}

.overlayContainer{
    width: 80%;
    height: 55% !important;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.overlayMsg{
    border: 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    width:600px;
    text-align: center;
}

.overlayMsg *{
   margin-bottom: 10px;
    
}

  .cs-message-separator {
    box-sizing: border-box;
    color: #111212 !important;
    background-color: #fff;
    font-size: 0.85em !important;
    font-family: Helvetica Neue,Segoe UI,Helvetica,Arial,sans-serif;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.processingDiv{
    display: flex;
   height: 100%;
   width: 100%;
   justify-content: center;
    align-items: center;
    
}

.processingContainer{
    border: 1px dashed green;
    border-radius: 25px;
    width: 60%;
    height: 28%;
    background: #FFF;
    font-size: medium;
}

.text-container{
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    align-items:center;
}

.activeDocContainer{
    /* border-bottom: 1px solid; */

    cursor: pointer;
    background: white;
    color: black;
    border-radius: 5px;
    padding: 3px;
    height: 30px;
}

.text {
    text-align: left;
    font-weight: 600;
    font-size:25px;
    margin-left: 50px;
    font-family: Inter;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .overviewDivWrapper {

    cursor:pointer; 
    height: 32px; 
    padding-left: 15px; 
    padding-right: 15px; 
    padding-top: 6px; 
    padding-bottom: 6px; 
    border-radius: 30px; 
    overflow: hidden; 
    border: 1px #89ac2f solid; 
    justify-content: center;
     align-items: center; 
     gap: 2px; 
     display: inline-flex;
     text-align: center; 
    color: #88AC2F; 
    font-family: Inter; 
    font-weight: 600; 
    text-transform: uppercase;
    word-wrap: break-word
  }

  .overviewDivWrapper:hover{
    background-color:  #89ac2f;
    color: #fff; 

  }

  .rs-modal-wrapper{
    display: flex;
    align-items: center;
  }




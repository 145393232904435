.container {
    width: 100%;
    display: flex;
    overflow: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 9vh;
  }
  .text {
    text-align: left;
    font-weight: 600;
    font-size:25px;
    margin-left: 50px;
    font-family: Inter;
    line-height: normal;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .overviewDivWrapper {

    cursor:pointer; 
    height: 32px; 
    padding-left: 15px; 
    padding-right: 15px; 
    padding-top: 6px; 
    padding-bottom: 6px; 
    border-radius: 30px; 
    overflow: hidden; 
    border: 1px #89ac2f solid; 
    justify-content: center;
     align-items: center; 
     gap: 2px; 
     display: inline-flex;
     text-align: center; 
    color: #88AC2F; 
    font-family: Inter; 
    font-weight: 600; 
    text-transform: uppercase;
    word-wrap: break-word
  }

  .overviewDivWrapper:hover{
    background-color:  #89ac2f;
    color: #fff; 

  }


  .banner {
    width: 100%;
    height: 633px;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    position: relative;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    padding-top: 2px;
    justify-content: space-between;
  }
  .image {
    width: 484px;
    height: 573px;
    object-fit: cover;
    flex-shrink: 0;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
  }
  .container1 {
    width: 97%;
    height: 95%;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    margin-right: var(--dl-space-space-sixunits);
    flex-direction: column;
    justify-content: space-between;
  }
  .container2 {
    width: 100%;
    height: 291px;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    margin-right: var(--dl-space-space-sixunits);
    flex-direction: column;
  }
  .text2 {
    margin-right: var(--dl-space-space-threeunits);
  }
  .container3 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
  }
  .textinput {
    font-size: 0.75rem;
    border-color: var(--dl-color-gray-500);
    border-radius: var(--dl-radius-radius-radius4);
  }
  .button {
    font-size: 0.75rem;
    transition: 0.3s;
    margin-left: var(--dl-space-space-unit);
    border-color: var(--dl-color-gray-500);
  }
  .button:hover {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-gray-black);
  }
  .container4 {
    
    bottom: 0;
    display: flex;
    position: unset;
    align-self: flex-start;
    align-items: flex-start;
    border: 1px solid gray;
    border-radius: 10px;
    margin-right: var(--dl-space-space-sixunits);
    flex-direction: column;
    justify-content: space-between;
  }
  .text3 {
    padding: 10px;
    align-self: flex-start;
    text-align: left;
    margin-right: 10px;
    font-size: small;
   
  }

  
  @media(max-width:1550px) {
    .banner {
      height: 480px;
    }
 
  
  }
 


  @media(max-width: 900px) {
    .banner {
      flex-direction: column;
    }
    .image {
      width: var(--dl-size-size-xxlarge);
      height: var(--dl-size-size-xxlarge);
    }
    .container1 {
      align-items: center;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .container2 {
      align-items: center;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .text2 {
      text-align: center;
      margin-left: var(--dl-space-space-threeunits);
    }
    .container3 {
      justify-content: center;
    }
    .container4 {
      align-items: left;
      margin-right: 0px;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .text3 {
      text-align: left;
      margin-left:10px;
    }
  }
  @media(max-width: 767px) {
    .banner {
      padding-left: var(--dl-space-space-twounits);
      padding-right: var(--dl-space-space-twounits);
    }
    .text2 {
      margin-left: var(--dl-space-space-unit);
      margin-right: var(--dl-space-space-unit);
    }
    .text3 {
      margin-left: var(--dl-space-space-unit);
      margin-right: var(--dl-space-space-unit);
    }
  }
  @media(max-width: 479px) {
    .banner {
      padding-top: var(--dl-space-space-twounits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
      padding-bottom: var(--dl-space-space-twounits);
    }
    .image {
      width: var(--dl-size-size-xlarge);
      height: var(--dl-size-size-xlarge);
    }
    .container3 {
      align-items: center;
      flex-direction: column;
    }
    .button {
      margin-top: var(--dl-space-space-unit);
      margin-left: 0px;
    }

    
    .container4 {
      margin-bottom: 100px;
    }
  }
  
.dashParentContainer{

    display:flex;
    justify-content:center;
}

.dashcomment {
    padding: 20px 24px;
    width:30%;
    margin: 0.5%;
  }



@media(max-width:420px) {
    .dashParentContainer{
         flex-direction: column;
    }
    .dashcomment {
        width:100%;
        
      }
  
  }
/* body {
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: sans-serif;
  }
  
  h1,
  p {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  } */
  
.emojiText{
    justify-content: center;
    display: flex;
    font-size: 18px;
    color: black;
}
  
  .feedBackContainer {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
  }
  .emojicontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .emojiItem {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  .emojiRadio {
    display: none;
  }
  .emojiRadio ~ span {
    font-size: 3rem;
    filter: grayscale(100);
    cursor: pointer;
    transition: 0.3s;
  }
  
  .emojiRadio:checked ~ span {
    filter: grayscale(0);
    font-size: 4rem;
  }
  

  .modalTitle{
    color: #272c36;
    color: black;
    font-weight: 500;
    display: block;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.11111111;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: flex;
    justify-content: center;
    display: flex;
  }
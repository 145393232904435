.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: #ffffff;
    color: rgb(66, 64, 64);
    border-bottom: 2px solid #ddd9d9;
    background: black;
    /* margin-bottom: 1rem; */
  }
  
  .navbar-logo h1 {
    margin: 0;
  }
  
  .navbar-logo {
    padding: 8px;
    margin-left: 10px;
  }

  .navbar-links {
    display: flex;
    gap: 1rem;
   
   
  }
  
  .navbar-links a {
    color: black;
    text-decoration: none;
    padding: 0.5rem;
    transition: background-color 0.3s;
  }
  
  .navbar-links a:hover {
    background-color: #f3f2f2;
  }
  
  .navbar-user {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .user-image {
    border-radius: 50%;
  }
  
  .menu-btn {
    display: none; /* Initially hide on larger screens */
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .navbar-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #ffffff;
      z-index: 1;
    }
  
    .navbar-links.active {
      display: flex;
    }
  
    .menu-btn {
      display: block; /* Display on smaller screens */
    }
  }

  

  

/* Google Font CDN Link */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
} */

::selection {
  background: #6d50e2;
  color: #fff;
}
.container {

  padding: 40px 50px 40px 40px;
  background: #fff;
  margin: 0 20px;
  height: 80vh;
  overflow-y: scroll;
  width: 98%;


}
.container .topic {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px;
  display: flex;
justify-content: center;
}

.list {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-right: 50px;
  position: relative;
}
.list label {
  height: 60px;
  font-size: 22px;
  font-weight: 500;
  line-height: 60px;
  cursor: pointer;
  padding-left: 25px;
  transition: all 0.5s ease;
  color: #333;
  z-index: 12;
}
#home:checked ~ .list label.profilehome,
#blog:checked ~ .list label.blog,
#help:checked ~ .list label.help,
#code:checked ~ .list label.code,
#about:checked ~ .list label.about {
  color: #fff;
}
.content .list label:hover {
  color: #6d50e2;
}
.content .slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 50px;
  width: 100%;
  border-radius: 12px;
  background: #6d50e2;
  transition: all 0.4s ease;
}
#home:checked ~ .list .slider {
  top: 1vh;
}
#blog:checked ~ .list .slider {
    top: 8vh;
}
#help:checked ~ .list .slider {
    top: 15vh;
}
#code:checked ~ .list .slider {
  top: 22vh;
}
#about:checked ~ .list .slider {
    top: 29vh;
}
.content .text-content {
  margin-top: 2vh;  
  width: 98%;
  height: 100%;
}
.content .text {
  display: none;
}
.content .text .title {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 500;
}
.content .text p {
  text-align: justify;
}
.content .text-content .profilehome {
  display: block;
}
#home:checked ~ .text-content .profilehome,
#blog:checked ~ .text-content .blog,
#help:checked ~ .text-content .help,
#code:checked ~ .text-content .code,
#about:checked ~ .text-content .about {
  display: block;
}
#blog:checked ~ .text-content .profilehome,
#help:checked ~ .text-content .profilehome,
#code:checked ~ .text-content .profilehome,
#about:checked ~ .text-content .profilehome {
  display: none;
}
.content input {
  display: none;
}

.drag-drop-container {
    border: 1px dashed #aaa;
    padding: 20px;
    border-radius: 35px;
    text-align: center;
    cursor: pointer;
    height: 20vh; /* Set height to 50% of the viewport height */
    display: flex; /* Enable Flexbox */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  
  .drag-drop-container.dragging {
    border-color: #007bff;
  }

  .bottom-text {
  /* Set it to the bottom */
    left: 0; /* Align it to the left (or adjust as per your needs) */
    width: 100%; /* Take the full width of the container */
    display: flex;
    font-weight: bold;
    justify-content: center;
    padding-bottom: 3vh;
    /* Optional: Add some padding */

  }

  
  .note-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    bottom: 10vh;
  }
  
  .note-heading {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
  }
  
  .note-content {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .point-list {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .contact-info {
    font-size: 14px;
    color: #3498db;
    text-decoration: underline;
  }
  
  @media only screen and (max-width: 600px) {
    .note-container {
      border-radius: 0;
      box-shadow: none;
    }
  }